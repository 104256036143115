'use client';

import React, { createContext, useContext, useState, ReactNode } from 'react';
import ReactDOM from 'react-dom';

interface ModalContextType {
  hideModal: (id: string) => void;
  showModal: (id: string, content: ReactNode) => void;
}

interface ModalProviderProps {
  children: ReactNode;
}

interface Modals {
  [id: string]: ReactNode;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modals, setModals] = useState<Modals>({});

  const showModal = (id: string, content: ReactNode): void => {
    setModals((prevModals) => ({
      ...prevModals,
      [id]: content,
    }));
  };

  const hideModal = (id: string): void => {
    setModals((prevModals) => {
      const newModals = { ...prevModals };
      delete newModals[id];
      return newModals;
    });
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {Object.entries(modals).map(([id, content]) =>
        ReactDOM.createPortal(
          React.cloneElement(content as React.ReactElement, {
            key: id,
            onClose: () => hideModal(id),
          }),
          document.body,
        ),
      )}
    </ModalContext.Provider>
  );
};
